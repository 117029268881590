import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useMemo } from "react";
import ImageComponent from "../../components/ImageComponent";
import { ClipLoader } from "react-spinners";

const CatalogStock = () => {
  const [productPaginationData, setProductPaginationData] = useState();
  const [productInventoryData, setProductInventoryData] = useState();
  const [productCatalogData, setProductCatalogData] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [photoIndex, setPhotoIndex] = useState(0);
  const { lid, catalogid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { catalog } = useSelector(getCatalog);

  useEffect(async () => {
    setLoading(true);
    const catalogDatastring = QueryString.stringify({
      populate: true,
      "catalogData.isActive": true,
      location: { $in: lid.split(",") },
      limit: 300,
    });

    const productData = await authAxiosInstance.get(
      `productInventory/catalog?${catalogDatastring}`
    );
    if (productData) {
      setProductCatalogData(productData.data?.data?.docs);
    }
    setLoading(false);
    dispatch(fetchCatalogs({ _id: catalogid, limit: 1, sort: { name: 1 } }));
  }, [catalogid]);

  const openImagePreview = (row) => {
    setPhotoIndex(row.index);
    setIsOpen(true);
  };

  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      console.log("searching");
      const string = QueryString.stringify({
        populate: true,
        location: lid,
        catalog: cid,
        search: search,
      });
      const productData = await authAxiosInstance.get(
        `productInventory?${string}`
      );
      if (productData) {
        setProductInventoryData(productData.data?.data?.docs);
        setProductPaginationData(productData.data?.data);
      }
    }, 300),
    []
  );
  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs[0] : []),
    [catalog]
  );
  const colsProductMemo = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row);
        return (
          <>
            {row.original?.productData?.image && (
              <ImageComponent
                src={row.original?.productData?.image}
                style={{ width: "120px" }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "product",
      Cell: (prop) => {
        const { data, row } = prop;
        return <>{row.original?.productData?.color}</>;
      },
    },
    {
      Header: "Pending",
      accessor: "pending",
    },
    {
      Header: "Unfinished",
      accessor: "unfinished",
    },
    {
      Header: "Pending Unfinished Embroidery",
      accessor: "pendingUnfinishedEmbroidery",
    },
    {
      Header: "Unfinished Embroidery",
      accessor: "unfinishedEmbroidery",
    },
    {
      Header: "Pending Job Work",
      accessor: "pendingJobWork",
    },
    {
      Header: "finished",
      accessor: "finished",
    },
    {
      Header: "Sold",
      accessor: "sold",
    },
    {
      Header: "In Transfer",
      accessor: "inTransfer",
    },
    {
      Header: "damaged",
      accessor: "damaged",
    },
  ];
  useEffect(async () => {
    if (catalogData?._id && lid) {
      const locationArr = lid.split(",");
      console.log(locationArr);
      const string = QueryString.stringify({
        populate: true,
        location: { $in: locationArr },
        catalog: catalogData?._id,
      });
      const productData = await authAxiosInstance.get(
        `productInventory?${string}`
      );
      const inventoryData = productData?.data?.data?.docs.reduce(
        (acc, ele, i) => {
          const productIndex = acc.findIndex(
            (el) => el.product === ele.product
          );
          if (productIndex === -1) {
            acc.push(ele);
          } else {
            acc[productIndex].pending = acc[productIndex].pending + ele.pending;
            acc[productIndex].unfinished =
              acc[productIndex].unfinished + ele.unfinished;
            acc[productIndex].pendingUnfinishedEmbroidery =
              acc[productIndex].pendingUnfinishedEmbroidery +
              ele.pendingUnfinishedEmbroidery;
            acc[productIndex].unfinishedEmbroidery =
              acc[productIndex].unfinishedEmbroidery + ele.unfinishedEmbroidery;
            acc[productIndex].pendingJobWork =
              acc[productIndex].pendingJobWork + ele.pendingJobWork;
            acc[productIndex].finished =
              acc[productIndex].finished + ele.finished;
            acc[productIndex].sold = acc[productIndex].sold + ele.sold;
            acc[productIndex].inTransfer =
              acc[productIndex].inTransfer + ele.inTransfer;
            acc[productIndex].damaged = acc[productIndex].damaged + ele.damaged;
          }
          return acc;
        },
        []
      );
      if (productData) {
        setProductInventoryData(inventoryData);
        setProductPaginationData(productData.data?.data);
      }
    }
  }, [lid, catalogData]);

  const findSrNo = () => {
    let findCatalogIndex = productCatalogData?.findIndex(
      (d) => d.catalog === catalogid
    );
    return findCatalogIndex + 1;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {loading ? (
        <ClipLoader />
      ) : (
        <>
          {/* Page header */}
          <div className="mb-8 flex gap-4">
            {/* Title */}
            <h2 className="text-xl md:text-3xl text-slate-800 font-bold">
              Stock for (No:-{findSrNo()}){" "}
              {productInventoryData &&
                productInventoryData.length > 0 &&
                productInventoryData[0].catalogData?.name}
            </h2>
            {catalogData && (
              <>
                {findSrNo() > 1 && (
                  <PrimaryButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (productCatalogData?.length > 0) {
                        let findProductCatalogIndex =
                          productCatalogData.findIndex(
                            (d) => d.catalog === catalogid
                          );
                        let prevCatalogId =
                          productCatalogData[findProductCatalogIndex - 1]
                            ?.catalog;
                        navigate(`/view-stock/${lid}/${prevCatalogId}`);
                      }
                    }}
                  >
                    Prev
                  </PrimaryButton>
                )}
                {findSrNo() !== productCatalogData?.length && (
                  <PrimaryButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (productCatalogData?.length > 0) {
                        let findProductCatalogIndex =
                          productCatalogData.findIndex(
                            (d) => d.catalog === catalogid
                          );
                        if (
                          findProductCatalogIndex ===
                          productCatalogData.length - 1
                        ) {
                          return alert("This is the Last Catalog");
                        }
                        let nextCatalogId =
                          productCatalogData[findProductCatalogIndex + 1]
                            ?.catalog;
                        navigate(`/view-stock/${lid}/${nextCatalogId}`);
                      }
                    }}
                  >
                    Next
                  </PrimaryButton>
                )}
              </>
            )}
          </div>

          {productInventoryData && (
            <>
              <TableWithHeadingAndGlobalSearch
                heading="Inventory"
                data={productInventoryData}
                columns={colsProductMemo}
                searchFunction={debouncedSearch}
              />
            </>
          )}
          <div className="bg-white shadow-lg rounded-sm mb-8">
            <div className="flex flex-col md:flex-row md:-mr-px"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default CatalogStock;
